<template>
  <div id="profile-nav">
    <router-link
      :class="{ active: $route.name == 'my-profile' }"
      :to="{ name: 'my-profile' }"
    >
      My Profile
    </router-link>

    <router-link
      :class="{ active: $route.name == 'my-projects' }"
      :to="{ name: 'my-projects' }"
    >
      My Orders
    </router-link>

    <router-link
      :class="{ active: $route.name == 'my-saved-items' }"
      :to="{ name: 'my-saved-items' }"
    >
      My Saved Items</router-link
    >
  </div>
</template>
<script>
export default {
  name: "ProfileNav"
};
</script>
<style lang="scss">
#profile-nav {
  background-color: $green;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 64px;
  width: 100vw;

  a {
    color: #fff;
    display: block;
    font-size: 1.2em;
    font-weight: bold;
    padding: 1em;

    &.active {
      background-color: $dark-green;
    }
  }
}
</style>
