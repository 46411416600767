<template>
  <div id="my-profile" class="account-container">
    <ProfileNav />

    <div class="container">
      <div class="header">
        <h1>MY PROFILE</h1>
      </div>

      <div class="table md-layout user-info">
        <div class="legend">
          <span>User</span>
        </div>

        <div
          class="column md-layout-item md-size-50 md-small-size-100 md-layout"
        >
          <div class="md-layout-item md-size-40 md-small-size-100 label">
            First Name:
          </div>
          <div class="md-layout-item md-size-60 md-small-size-100 name">
            {{ user.first_name }}
          </div>

          <div class="md-layout-item md-size-40 md-small-size-100 label">
            Last Name:
          </div>
          <div class="md-layout-item md-size-60 md-small-size-100 name">
            {{ user.last_name }}
          </div>

          <div
            v-if="user.company"
            class="md-layout-item md-size-40 md-small-size-100 label"
          >
            Company:
          </div>
          <div
            v-if="user.company"
            class="md-layout-item md-size-60 md-small-size-100 name"
          >
            {{ user.company }}
          </div>

          <div class="md-layout-item md-size-40 md-small-size-100 label">
            Email:
          </div>
          <div class="md-layout-item md-size-60 md-small-size-100 name">
            {{ user.email }}
          </div>

          <div class="md-layout-item md-size-40 md-small-size-100 label">
            Registration type:
          </div>
          <div class="md-layout-item md-size-60 md-small-size-100 name">
            {{ user.registration_type_desc }}
          </div>

          <div class="md-layout-item md-size-40 md-small-size-100 label">
            Record Company:
          </div>
          <div class="md-layout-item md-size-60 md-small-size-100 name">
            {{ user.record_company_indicator == "Y" ? "Yes" : "No" }}
          </div>
        </div>

        <div
          class="column md-layout-item md-size-50 md-small-size-100 md-layout"
        >
          <md-button
            :to="{ name: 'change-password' }"
            class="md-primary md-raised"
          >
            Change Password
          </md-button>
        </div>
      </div>

      <div class="table md-layout">
        <div class="legend">
          <span>Licensee</span>
        </div>

        <div
          class="column md-layout-item md-size-50 md-small-size-100 md-layout"
        >
          <div class="md-layout-item md-size-40 md-small-size-100 label">
            Licensee Name:
          </div>
          <div class="md-layout-item md-size-60 md-small-size-100 name">
            {{ user.licensee_name }}
          </div>

          <div class="md-layout-item md-size-40 md-small-size-100 label">
            Street Address 1:
          </div>
          <div class="md-layout-item md-size-60 md-small-size-100 name">
            {{ user.street_address }}
          </div>

          <div
            v-if="user.street_address_two"
            class="md-layout-item md-size-40 md-small-size-100 label"
          >
            Street Address 2:
          </div>
          <div
            v-if="user.street_address_two"
            class="md-layout-item md-size-60 md-small-size-100 name"
          >
            {{ user.street_address_two }}
          </div>

          <div class="md-layout-item md-size-40 md-small-size-100 label">
            Phone Number:
          </div>
          <div class="md-layout-item md-size-60 md-small-size-100 name">
            {{ user.phone_number }}
          </div>
        </div>

        <div
          class="column md-layout-item md-size-50 md-small-size-100 md-layout"
        >
          <div class="md-layout-item md-size-40 md-small-size-100 label">
            City:
          </div>
          <div class="md-layout-item md-size-60 md-small-size-100 name">
            {{ user.city }}
          </div>

          <div class="md-layout-item md-size-40 md-small-size-100 label">
            State/Province:
          </div>
          <div class="md-layout-item md-size-60 md-small-size-100 name">
            {{ user.state }}
          </div>

          <div
            v-if="user.country"
            class="md-layout-item md-size-40 md-small-size-100 label"
          >
            Country:
          </div>
          <div
            v-if="user.country"
            class="md-layout-item md-size-60 md-small-size-100 name"
          >
            {{ user.country }}
          </div>

          <div class="md-layout-item md-size-40 md-small-size-100 label">
            Zip/Postal Code:
          </div>
          <div class="md-layout-item md-size-60 md-small-size-100 name">
            {{ user.zip_code }}
          </div>
        </div>
      </div>

      <md-button class="md-raised md-primary" @click="showAccountForm = true">
        Edit
      </md-button>
    </div>

    <md-dialog
      @md-closed="closeDialog"
      :md-active.sync="showAccountForm"
      :md-close-on-esc="isNoiReady"
      :md-click-outside-to-close="isNoiReady"
    >
      <md-dialog-title>
        <span>YOUR PROFILE</span>

        <md-button
          class="md-raised md-dense md-icon-button close"
          @click="showAccountForm = false"
          v-if="isNoiReady"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>

      <md-dialog-content>
        <AccountForm @closeDialog="showAccountForm = false" />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProfileNav from "@/components/ProfileNav.vue";
import AccountForm from "@/components/AccountForm.vue";

export default {
  name: "MyProfile",
  components: {
    ProfileNav,
    AccountForm
  },
  metaInfo: function() {
    return {
      title: " - My Profile",
      meta: [
        {
          name: "description",
          content: "View and update your profile information."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      showAccountForm: false
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    isNoiReady() {
      return this.$store.getters["auth/isNOIReady"];
    }
  },
  methods: {
    closeDialog: function() {
      this.showAccountForm = false;
    }
  },
  mounted: function() {
    if (!this.isNoiReady) {
      this.showAccountForm = true;
    }
  }
};
</script>

<style lang="scss">
#my-profile {
  .table {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid #e0e0e0;
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 0 20px 15px;

    .legend {
      width: 100%;
      font-size: 1.5em;
      font-weight: bold;
      margin-bottom: -15px;

      span {
        background-color: #f6f6f6;
        border: 1px solid #e0e0e0;
        border-radius: 3px;
        display: inline-block;
        padding: 10px 30px;
        position: relative;
        top: -20px;
        left: 50px;
      }
    }

    .column {
      font-size: 1.2em;
      display: flex;
      flex-wrap: wrap;

      > div {
        padding: 10px 0;
      }

      .label {
        font-weight: bold;

        @media (max-width: 960px) {
          padding-bottom: 0;
          margin-top: 10px;
        }
      }
    }

    &.user-info {
      align-items: center;

      .column:last-child {
        justify-content: center;
      }
    }
  }

  .md-button {
    width: 150px;
  }
}
</style>
